import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Markdown from "react-markdown"
import Layout from "../components/common/layout"
import InfoCard from "../components/cards/infoCard"
import MostraCard from "../components/cards/mostraCard"
import indexStyles from "../styles/pages/index.module.scss"
import { LinkRenderer, HeadingRenderer } from "../utilities/markdownUtils"
import markdownStyles from "../styles/common/markdown.module.scss"
import video from "../assets/video/sdb.mp4"
import cover from "../images/cover.jpg"
import logo from "../images/icon.jpg"
import { isDateEarlier, formatDate } from "../utilities/dateUtils"

const Home = ({ data, location }) => {
  const today = new Date().toLocaleDateString("it-IT", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  })

  const isOrarioAttivo = (orario) =>
    isDateEarlier(today, formatDate(orario.scadenza)) &&
    isDateEarlier(formatDate(orario.attivazione), today)

  const orari = data.strapiOrari.blocco_orari || []
  const orariAttivi = orari.filter((orario) => isOrarioAttivo(orario))
  const images = data.strapiVetrina.multimedia || []
  const approfondimenti = data.strapiVetrina.approfondimentis || []
  const commenti = data.allStrapiCommentiLettureDomenicali.edges || []

  return (
    <Layout
      pageMeta={{
        title: "Benvenuti",
        keywords: [
          "Boca",
          "Santuario del SS Crocifisso di Boca",
          "Santuario di Boca",
          "Crocifisso di Boca",
          "Antonelli",
          "padre Charles-Eugène de Foucauld",
        ],
        description:
          "Il santuario del Santissimo Crocifisso è una basilica minore situata a Boca tra le colline novaresi, in provincia e diocesi di Novara. Il santuario è meta frequente di pellegrinaggi.",
        link: "/",
      }}
      location={location}
      cssClass={indexStyles.pageWrapper}
    >
      {/*  <section id="orari" className={indexStyles.orari}>
        <ul className={indexStyles.items}>
          <li className={indexStyles.itemDisplay}>
            <img className={indexStyles.logo} src={logo} alt="logo" />
            <h2>orari</h2>
          </li>
          {orariAttivi.length &&
            orariAttivi.map((orario) => (
              <li className={indexStyles.itemDisplay} key={orario.id}>
                <div>
                  <h3>{orario.titolo}</h3>
                  <Markdown
                    className={markdownStyles.testo}
                    renderers={{ heading: HeadingRenderer, link: LinkRenderer }}
                    source={orario.descrizione}
                  />
                </div>
              </li>
            ))}
        </ul>
      </section> */}
 {/*              <div style={{ position: "relative" }}>
      <Img
          fluid={images[6].file.childImageSharp.fluid}
          alt={images[6].ext}
      />
        <p className={indexStyles.festaIntro}>
         "Sorridiamo ed esultiamo insieme con la beata Vergine, perché Dio ci ha dato il sorriso, cioè il motivo di sorridere e di gioire"
           - <i>Sant'Antonio di Padova</i> <br /><br />
           Preghiamo perché anche tutti i fratelli che sono coinvolti nelle guerre
           depongano le armi e possano illuminare i loro volti
           con questo sorriso di Maria per la nascita di Gesù. <br /><br />Buone feste.
       
         {/* <h2 style={{fontSize: "1.1rem"}}> I padri del Santuario augurano a tutti un santo Natale <br /> e un buon inizio del nuovo anno nella pace. </h2> */}
         {/* </p> </div> */}
      <section className={indexStyles.letture} style={{ position: "relative" }}>
       
        <video className={indexStyles.video} poster={cover} loop autoPlay muted>
          <source src={video} type="video/mp4" />
          <source src={video} type="video/ogg" />
        </video>

        {/* <ul className={indexStyles.items}> */}
        <div className={indexStyles.display}>
          <img className={indexStyles.logo} src={logo} alt="logo" />
          <h2>orari</h2>
        </div>
        <section id="orari" className={indexStyles.items}>
          <ul className={indexStyles.festa}>
            {orariAttivi.length &&
              orariAttivi.map((orario) => (
                <li className={indexStyles.itemDisplay} key={orario.id}>
                  <article className={markdownStyles.testo}>
                    <h4>{orario.titolo}</h4>
                    <Markdown
                      className={markdownStyles.testo}
                      renderers={{
                        heading: HeadingRenderer,
                        link: LinkRenderer,
                      }}
                      source={orario.descrizione}
                    />
                  </article>
                </li>
              ))}
          </ul>
        </section>
        {/* festa del santuario */}
        {/*  <section id="orari" className={indexStyles.festa}>
        <h2 style={{textAlign: "center"}}>Celebrazioni Santo Natale 2022</h2>

          <article className={`${markdownStyles.testo} ${indexStyles.border}`}>
            <h4>Mercoledì 21 dicembre</h4>
            <p>
              <span>Ore 21:00</span> - Adorazione Eucaristica
            </p>
          </article>
          <article className={`${markdownStyles.testo} ${indexStyles.border}`}>
            <h4>Tutti i gioni dal Lunedì al Sabato</h4>
            <p>
              <span>Ore 10:00 - 12:00</span> - Confessioni Natalizie
            </p>
            <p>
              <span>Ore 14:30 - 18:00</span> - Confessioni Natalizie
            </p>
          </article>
          <article className={`${markdownStyles.testo} ${indexStyles.border}`}>
            <h4>Sabato 24 dicembre</h4>
            <p>Vigilia del Santo Natale</p>
            <p>
              <span>Ore 16:00</span> - Santa Messa della Vigilia
            </p>
            <p>
              <span>Ore 22:00</span> - Santa Messa della Notte
            </p>
          </article>
          <article className={`${markdownStyles.testo} ${indexStyles.border}`}>
            <h4>Domenica 25 dicembre</h4>
            <p>Solennità del Santo Natale</p>
            <p>
              <span>Ore 09:00</span> - Santa Messa
            </p>
            <p>
              <span>Ore 11:00</span> - Santa Messa
            </p>
            <p>
              <span>Ore 16:00</span> - Santa Messa
            </p>
          </article>
          <article className={`${markdownStyles.testo} ${indexStyles.border}`}>
            <h4>Lunedì 26 dicembre</h4>
            <p>Festa di Santo Stefano</p>
            <p>
              <span>Ore 11:00</span> - Santa Messa
            </p>
            <p>
              <span>Ore 16:00</span> - Santa Messa
            </p>
          </article>

          <article className={`${markdownStyles.testo} ${indexStyles.border}`}>
            <h4>Mercoledì 28 dicembre</h4>
            <p>
              <span>Ore 21:00</span> - Adorazione Eucaristica
            </p>
          </article>
          <article className={`${markdownStyles.testo} ${indexStyles.border}`}>
            <h4>Sabato 31 dicembre</h4>
            <p>Ultimo dell&apos;anno</p>
            <p>
              <span>Ore 16:00</span> - Santa Messa di Ringraziamento con il
              canto del Te Deum
            </p>
            <p>
              <span>Ore 21:00</span> - Adorazione e Veglia per la Pace
            </p>
          </article>
          <article className={`${markdownStyles.testo} ${indexStyles.border}`}>
            <h4>Domenica 1 gennaio</h4>
            <p>Solennità di Maria SS. Madre di Dio</p>
            <p>
              <span>Ore 09:00</span> - Santa Messa
            </p>
            <p>
              <span>Ore 11:00</span> - Santa Messa
            </p>
            <p>
              <span>Ore 16:00</span> - Santa Messa
            </p>
          </article>
          <article className={`${markdownStyles.testo} ${indexStyles.border}`}>
            <h4>Mercoledì 4 gennaio</h4>
            <p>
              <span>Ore 21:00</span> - Adorazione Eucaristica
            </p>
          </article>
          <article className={`${markdownStyles.testo} ${indexStyles.border}`}>
            <h4>Venerdì 6 gennaio</h4>
            <p>Solennità dell&apos;Epifania</p>
            <p>
              <span>Ore 09:00</span> - Santa Messa
            </p>
            <p>
              <span>Ore 11:00</span> - Santa Messa
            </p>
            <p>
              <span>Ore 16:00</span> - Santa Messa
            </p>
          </article>
          <article className={`${markdownStyles.testo} ${indexStyles.border}`}>
            <h4>Venerdì 13 gennaio</h4>
            <p>
              <span>Ore 21:00</span> - Santa Messa per i giovani defunti
            </p>
          </article> */}
        {/* <article className={markdownStyles.testo}>
            <h4>Venerdì 2 settembre</h4>
            <p>
              <span>Ore 21:00</span> - S. Messa per i giovani defunti.
            </p>
          </article>
          <article className={markdownStyles.testo}>
            <h4>Mercoledì 7 settembre</h4>
            <p>
              <span>Ore 21:00</span> - Adorazione
            </p>
            <p>
            Maria, l’Addolorata, nel magistero della Chiesa. Padre Mario Airoldi

            </p>
          </article>
          <article className={markdownStyles.testo}>
            <h4>Domenica 11 settembre</h4>
            <p>
              <span>Ore 14:30</span> - Lectio biblica comunitaria.
            </p>
          </article>
          <article className={markdownStyles.testo}>
            <h4>Martedì 14 settembre - Solennità dell’Esaltazione della Croce</h4>
            <p>
              <span>Ore 17:00</span> - S. Messa nello Scurolo del Crocifisso
            </p>
            <p>
              <span>Ore 18:00</span> - Riflessione per catechisti e operatori pastorali. Relatrice: Monica Prandi, responsabile diocesana per la catechesi.
            </p>
            <p>Al termine: Adorazione silenziosa e confessioni</p>
            <p>
              <span>Ore 21:00</span> - Concelebrazione solenne dell’Esaltazione della Croce, presieduta da <span>Don Massimo Casaro</span>, nuovo padre spirituale del Seminario e direttore del Centro Missionario Diocesano. <span>La S. Messa sarà animata dai Seminaristi</span>.
            </p>
          </article>
          <article className={markdownStyles.testo}>
            <h4>Venerdì 16 settembre</h4>
            <p>
              <span>Ore 21:00</span> - Assemblea diocesana
            </p>
          </article>
          <article className={markdownStyles.testo}>
            <h4>Sabato 17 settembre</h4>
            <p>
              <span>Ore 17:00</span> - 
            S. Messa per i collaboratori del Santuario, vivi e defunti.
            </p>
            <p>
La Santa Messa sarà animata dall’orchestra giovanile di Borgomanero “ I Borgomusici”, al termine canti e musiche mariani.	<br/>
Cena per i volontari.

            </p>
          </article>
          <article className={markdownStyles.testo}>
            <h4>Mercoledì 21 settembre</h4>
            <p>
              <span>Ore 21:00</span> - Adorazione
            </p>
            <p>
            “Maria nel Nuovo Testamento”, catechesi tenuta da don Alberto Olivo.
            </p>
          </article>
          <article className={markdownStyles.testo}>
            <h4>Venerdì 23 settembre</h4>
            <p>
              <span>Ore 21:00</span> - Preghiera di Taizé presso il Santuario della Madonna della Bocciola - Ameno. La preghiera di Maria: il Magnificat. 

            </p>
            <p>Meditazione biblica tenuta da don Alberto Olivo. </p>
          </article>
          <article className={markdownStyles.testo}>
            <h4>Domenica 25 settembre</h4>
            <p>
              <span>Ore 14:30</span> - Lectio biblica comunitaria
            </p>
          </article>
          <article className={markdownStyles.testo}>
            <h4>Mercoledì 28 settembre</h4>
            <p>
              <span>Ore 21:00</span> - Adorazione
              <p>La figura di Maria nell’arte. Riflessione tenuta da don Mario Vanini.</p>

            </p>
          </article>
          <article className={markdownStyles.testo}>
            <h4>Domenica 2 ottobre - Conclusione della festa</h4>
            <p>
              <span>Ore 17:00</span> - Messa in onore della Madonna Addolorata, animata dal Coro di Valmadrera.

            </p>
          </article> */}
        {/* </section> */}
        {/* festa del santuario */}
        <ul className={indexStyles.btns}>
        <Link
            to={`/meditazioni-e-approfondimenti/${approfondimenti[3].slug}`}
            className={indexStyles.btn}
            key={`${approfondimenti[3].slug}`}
          >
            <>
              <span>{approfondimenti[3].titolo}</span>
              {images[8].file &&
                images[8].file.ext !== ".mp3" &&
                images[8].file.ext !== ".pdf" && (
                  <Img
                    fluid={images[9].file.childImageSharp.fluid}
                    alt={images[9].ext}
                  />
                )}
            </>
          </Link>
        <Link
            to={`/meditazioni-e-approfondimenti/${approfondimenti[2].slug}`}
            className={indexStyles.btn}
            key={`${approfondimenti[2].slug}`}
          >
            <>
              <span>{approfondimenti[2].titolo}</span>
              {images[6].file &&
                images[6].file.ext !== ".mp3" &&
                images[6].file.ext !== ".pdf" && (
                  <Img
                    fluid={images[7].file.childImageSharp.fluid}
                    alt={images[7].ext}
                  />
                )}
            </>
          </Link>
{/*                   <Img
                  style={{padding:"0", cursor: "auto", transform: "scale(1)"}}
                  className={indexStyles.btn}
                    fluid={images[8].file.childImageSharp.fluid}
                    alt={images[8].ext}
                  /> */}
                <MostraCard />
          <Link
            to="/commenti-alle-letture-domenicali"
            className={indexStyles.btn}
            key="/commenti-alle-letture-domenicali"
          >
            <span>
              Commenti alle letture domenicali del Monastero Benedettino di
              Saint-Oyen
            </span>
            {images[3].file &&
              images[3].file.ext !== ".mp3" &&
              images[3].file.ext !== ".pdf" && (
                <Img
                  fluid={images[3].file.childImageSharp.fluid}
                  alt={images[3].ext}
                />
              )}
          </Link>
          <Link
            to="/meditazioni-e-approfondimenti"
            key="/meditazioni-e-approfondimenti"
            className={indexStyles.btn}
          >
            <span>Meditazioni e approfondimenti</span>
            {images[4].file &&
              images[4].file.ext !== ".mp3" &&
              images[4].file.ext !== ".pdf" && (
                <Img
                  fluid={images[4].file.childImageSharp.fluid}
                  alt={images[4].ext}
                />
              )}
          </Link>
          <Link
            to="/storia-del-santuario"
            key="/storia-del-santuario"
            className={indexStyles.btn}
          >
            <>
              <span>Storia del santuario</span>
              {images[0].file &&
                images[0].file.ext !== ".mp3" &&
                images[0].file.ext !== ".pdf" && (
                  <Img
                    fluid={images[0].file.childImageSharp.fluid}
                    alt={images[0].ext}
                  />
                )}
            </>
          </Link>
          <Link
            to={`/meditazioni-e-approfondimenti/${approfondimenti[0].slug}`}
            className={indexStyles.btn}
            key={`${approfondimenti[0].slug}`}
          >
            <>
              <span>{approfondimenti[0].titolo}</span>
              {approfondimenti[0].multimedia[0].file &&
                approfondimenti[0].multimedia[0].file.ext !== ".mp3" &&
                approfondimenti[0].multimedia[0].file.ext !== ".pdf" && (
                  <Img
                    fluid={
                      approfondimenti[0].multimedia[0].file.childImageSharp
                        .fluid
                    }
                    alt={approfondimenti[0].multimedia[0].ext}
                  />
                )}
            </>
          </Link>
{/*           <Link
            to="/commenti-alle-letture-domenicali"
            className={indexStyles.btn}
            key="/commenti-alle-letture-domenicali/latest"
          >
            <>
              <span>
                {commenti[0].node.data} <br />
                {commenti[0].node.titolo}
              </span>
              {images[2].file &&
                images[2].file.ext !== ".mp3" &&
                images[2].file.ext !== ".pdf" && (
                  <Img
                    fluid={images[2].file.childImageSharp.fluid}
                    alt={images[2].ext}
                  />
                )}
            </>
          </Link> */}
          <Link
            to={`/meditazioni-e-approfondimenti/${approfondimenti[1].slug}`}
            className={indexStyles.btn}
            key={`${approfondimenti[1].slug}`}
          >
            <>
              <span>{approfondimenti[1].titolo}</span>
              {images[1].file &&
                images[1].file.ext !== ".mp3" &&
                images[1].file.ext !== ".pdf" && (
                  <Img
                    fluid={images[1].file.childImageSharp.fluid}
                    alt={images[1].ext}
                  />
                )}
            </>
          </Link>
          <InfoCard />  
        </ul>
      </section>
      <div className={indexStyles.websites}>
        <a
          style={{ width: "100%" }}
          href="https://www.vaticannews.va/it.html"
          target="_blank"
          rel="noreferrer"
        >
          Vatican News - Notizie su Papa, Vaticano, Chiesa, Mondo
        </a>
      </div>
      <div className={indexStyles.websites}>
        <a
          href="https://www.diocesinovara.it/"
          target="_blank"
          rel="noreferrer"
        >
          Diocesi di Novara
        </a>

        <a href="https://www.sdnovarese.it/" target="_blank" rel="noreferrer">
          Settimanale della Diocesi di Novara
        </a>

        <a
          href="https://www.chiesacattolica.it/"
          target="_blank"
          rel="noreferrer"
        >
          Chiesa Cattolica
        </a>

        <a href="http://www.qumran2.it/" target="_blank" rel="noreferrer">
          Qumran2
        </a>

        <a
          href="https://www.lagomaggioreguide.com/"
          target="_blank"
          rel="noreferrer"
        >
          Lago Maggiore Guide
        </a>
        <a
          href="https://chiesadellabibbia.altervista.org/"
          target="_blank"
          rel="noreferrer"
        >
          Chiesa della Bibbia
        </a>
      </div>
    </Layout>
  )
}

export default Home

export const query = graphql`
  query HomeQuery {
    strapiVetrina {
      approfondimentis {
        titolo
        slug
        autore
        categoria
        multimedia {
          file {
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      multimedia {
        file {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    strapiOrari {
      blocco_orari {
        id
        attivazione
        scadenza
        titolo
        descrizione
      }
    }
    allStrapiCommentiLettureDomenicali(
      limit: 1
      sort: { order: DESC, fields: data }
    ) {
      edges {
        node {
          titolo
          data(formatString: "DD MMMM, YYYY", locale: "it")
        }
      }
    }
  }
`
