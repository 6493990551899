import React from "react"
import indexStyles from "../../styles/pages/index.module.scss"

const titolo = "Bollettino del Santuario di Boca"

const MostraCard = () => (
  <div className={indexStyles.infoWrapper}>
    <div key={titolo} className={indexStyles.info} role="presentation">
      <h2 className={indexStyles.infoTitolo}>{titolo}</h2>
      <p className={indexStyles.infoTesto}>
        {`lo troverai all'interno del Settimanale Diocesano il primo venerdì del
      mese`}
      </p>
      <p className={indexStyles.infoTesto}>
        costa solo 15 euro annuali - lascia il tuo contributo in sagrestia
      </p>
    </div>
  </div>
)

export default MostraCard
