import React from "react"
import indexStyles from "../../styles/pages/index.module.scss"

const titolo = "IN MOSTRA LA STORIA DEL SANTUARIO"

const InfoCard = () => (
  <div className={indexStyles.infoWrapper}>
    <div key={titolo} className={indexStyles.info} role="presentation">
      <h2 className={indexStyles.infoTitolo}>{titolo}</h2>
      <p className={indexStyles.infoTesto}>
        {`VISITE GUIDATE OGNI SABATO E DOMENICA con INGRESSO AD OFFERTA LIBERA`}
      </p>
      <p className={indexStyles.infoTesto}>
        {`RICHIESTA PRENOTAZIONE - chiamare 349.412.9792  oppure 0322.87142`}
      </p>
      <p className={indexStyles.infoTesto}>
      La mostra  “IL SANTUARIO DEL SS. CROCIFISSO DI BOCA AD OPERA DI ALESSANDRO ANTONELLI” è stata inaugurata nel settembre 2021 ed è stata organizzata grazie al Santuario, al Club per l’Unesco “Terre del Boca” e alla Fondazione Cassa di Risparmio di Torino. 
      </p>
    </div>
  </div>
)

export default InfoCard
